import dashboard from './dashboard'
import main from './main'
import marketOrder from './marketOrder'
import marketEvent from './marketEvent'
import marketItem from './marketItem'
import marketCourse from './marketCourse'
import marketBalance from './marketBalance'
import license from './license'
import application from './application'
import member from './member'
import notification from './notification'
import setting from './setting'

export default [
	{
		path: '/mypage',
		name: 'mypage',
		component: () => import('@/pages/mypage/MyPage.vue'),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
		children: [
			...dashboard,
			...main,
			...marketOrder,
			...marketItem,
			...marketEvent,
			...marketCourse,
			...marketBalance,
			...license,
			...application,
			...member,
			...notification,
			...setting,
		],
	},
]
