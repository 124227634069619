<template>
	<v-navigation-drawer
		:value="isDrawerOpen"
		app
		floating
		width="260"
		class="app-navigation-menu pr-2 pt-8"
		:right="$vuetify.rtl"
		touchless
		@input="val => $emit('update:is-drawer-open', val)"
	>
		<div
			class="d-flex align-center justify-center nav-menu-logo"
			style="min-height: 30px"
		>
			<router-link
				:to="{ name: 'main' }"
				class="d-flex align-center text-decoration-none"
			>
				<v-img
					:src="require('@/assets/logo/logo__white.png')"
					max-height="58px"
					alt="logo"
					contain
					eager
					class="app-logo me-3"
				/>
			</router-link>
		</div>
		<v-list
			expand
			shaped
			class="vertical-nav-menu-items d-flex flex-column pr-0 mt-8"
		>
			<v-list-item-group>
				<div v-for="(item, index) in menuGroupItems" :key="'group-' + index">
					<div v-if="item.children">
						<nav-menu-group :title="item.text" :icon="item.icon" v-if="profile">
							<div
								v-for="(child, index) in item.children"
								:key="'group-item-' + index"
							>
								<nav-menu-link
									class="child-link"
									:title="child.text"
									:to="child.route"
									:role="child.role"
								/>
							</div>
						</nav-menu-group>
					</div>
					<div v-else>
						<nav-menu-link
							:title="item.text"
							:to="item.route"
							:icon="item.icon"
							:role="item.role"
						/>
					</div>
				</div>
				<div>
					<v-list-item @click="signout">
						<v-list-item-icon>
							<v-icon color="white" size="23">mdi-logout-variant</v-icon>
						</v-list-item-icon>
						<v-list-item-title class="white--text cursor-pointer text-sm">
							{{ $t('appLeft.logout') }}
						</v-list-item-title>
					</v-list-item>
				</div>
			</v-list-item-group>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'

import i18n from '@/i18n'

import store from '@/store'
import { useRouter } from '@core/utils'
import { marketerRole } from '@/helpers'
import { confirmSwal } from '@/plugins/swalMixin'

import AuthService from '@/services/AuthService'
import MarketCourseService from '@/services/MarketCourseService'

import NavMenuLink from '../common/NavMenuLink.vue'
import NavMenuGroup from '../common/NavMenuGroup.vue'

export default {
	components: {
		NavMenuLink,
		NavMenuGroup,
	},
	props: {
		isDrawerOpen: {
			type: Boolean,
			default: null,
		},
	},
	setup() {
		const { router } = useRouter()
		const profile = computed(() => store.getters['user/getMeDetail'])
		const myMarketCourses = ref([])

		const menuGroupItems = ref([
			{
				text: '대시보드',
				icon: 'mdi-view-dashboard-variant',
				route: { name: 'mypage-dashboard' },
			},
			{
				text: '마이데이터',
				icon: 'mdi-account-circle',
				route: { name: 'mypage-main' },
			},
			{
				text: '신청보드',
				icon: 'mdi-application-edit',
				children: [
					{
						text: '이벤트',
						route: { name: 'mypage-market-event' },
					},
					{
						text: '자격증',
						route: { name: 'mypage-license' },
					},
					{
						text: '신청현황',
						route: { name: 'mypage-market-application' },
					},
					{
						text: '주문 내역',
						route: { name: 'mypage-market-order' },
					},
				],
			},
			{
				text: '알림',
				icon: 'mdi-bell',
				route: { name: 'mypage-notification' },
			},
			{
				text: '리워드 포인트',
				icon: 'mdi-ticket',
				route: { name: 'mypage-market-balance' },
				role: [marketerRole.ADMIN.value, marketerRole.None.value, ''],
			},
			{
				text: '설정',
				icon: 'mdi-account-cog-outline',
				route: { name: 'mypage-setting' },
			},
		])

		const signout = async () => {
			const confirm = await confirmSwal({
				html: i18n.t('signOutForm.html'),
				confirmButtonText: i18n.t('signOutForm.confirmButtonText'),
				cancelButtonText: i18n.t('signOutForm.cancelButtonText'),
			})
			if (confirm.isConfirmed) {
				await AuthService.signout()
				router.push('/')
			}
		}

		const routeToAdmin = () => {
			const adminUrl = process.env.VUE_APP_ADMIN_URL
			window.open(adminUrl, '_blank')
		}

		const isExistMyMarketCourses = async () => {
			const lectureMenuObj = {
				text: '강의실',
				route: { name: 'mypage-market-course' },
			}

			try {
				myMarketCourses.value = await MarketCourseService.listMyMarketCourses()
				if (
					menuGroupItems.value &&
					menuGroupItems.value[2] &&
					menuGroupItems.value[2].children
				) {
					menuGroupItems.value[2].children.unshift(lectureMenuObj)
				}
			} catch (e) {
				console.log(e)
			}
		}

		onMounted(() => {
			isExistMyMarketCourses()
		})

		return {
			profile,
			menuGroupItems,
			marketerRole,
			myMarketCourses,

			signout,
			routeToAdmin,
		}
	},
}
</script>
<style lang="scss" scoped>
.theme--light.v-navigation-drawer {
	background-color: #0f2c5c;
	&::-webkit-scrollbar-thumb {
		background-color: #ffffff;
	}
}
</style>
