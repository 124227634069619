export default [
	{
		path: '/auth/phone-number',
		name: 'auth-phone-number',
		component: () => import('@/pages/auth/AuthPhoneNumber.vue'),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
		async beforeEnter(to, from, next) {
			next()
		},
	},
	{
		path: '/auth/kakao-link',
		name: 'auth-kakao-link',
		component: () => import('@/pages/auth/AuthKakaoLink.vue'),
		meta: {
			layout: 'LayoutContent',
			requiresAuth: true,
		},
		async beforeEnter(to, from, next) {
			next()
		},
	},
	{
		path: '/auth/kakao-signin',
		name: 'auth-kakao-signin',
		component: () => import('@/pages/auth/AuthKakaoSignin.vue'),
		meta: {
			layout: 'LayoutContent',
		},
		async beforeEnter(to, from, next) {
			next()
		},
	},
]
